import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import {
  gql,
  useMutation,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Notyf } from "notyf";
import FOOTERCOMMON from "../../assets/FOOTERCOMMON.png";
import "./SuccessCoachEmail.css";
import TICK from "../../assets/TICK.png";

// Initialize Notyf for notifications
const notyf = new Notyf({
  duration: 2000,
  position: {
    x: "right",
    y: "top",
  },
  types: [
    {
      type: "warning",
      background: "orange",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "warning",
      },
    },
    {
      type: "success",
      background: "green",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "check",
      },
    },
    {
      type: "error",
      background: "red",
      duration: 2000,
      dismissible: false,
    },
  ],
});

interface SchoolOption {
  name: string;
}

interface Option {
  id: number;
  name: string;
}

const option: Option[] = [
  {
    id: 1,
    name: "School",
  },
  {
    id: 2,
    name: "Academic",
  },
];

const REGISTER_COACH = gql`
  mutation RegisterCoach($input: RegisterCoachInput!) {
    registerCoach(input: $input) {
      message
      status
      token
    }
  }
`;

// Create an Apollo client instance with the temporary token in the headers
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("tempToken");
  return {
    headers: {
      ...headers,
      Authorization: token ? `jwt ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const SuccessCoachEmail: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  // State for user inputs
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") ?? "");
  const [schoolID, setSchoolID] = useState(null);

  console.log("schoolID", schoolID);

  const [selctedIns, setSelectedIns] = useState<string>("");

  const [selctedGender, setSelectedGender] = useState<string>("");
  const [slctOption, setSlctOption] = useState(
    localStorage.getItem("schoolAcademic")
  );

  const selectSchool = (e: any) => {
    console.log("select school", e.target.value);

    setSchoolID(e.target.value);
  };

  const [schoolList, setSchoolList] = useState([]);

  console.log("schoolList", schoolList);

  const [registerCoach, { loading, error }] = useMutation(REGISTER_COACH, {
    client,
  });

  useEffect(() => {
    setShowConfetti(true);
  }, []);

  const domainToSchoolIdMap: Record<string, number> = {
    "school1.com": 1,
    "school2.com": 2,
    "school3.com": 3,
    "school4.com": 4,
  };

  const fetchSchools = async () => {
    const token = localStorage.getItem("token");
    const isAcademic = slctOption == "ACADEMIC";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          query: ` query GetAllSchools($isAcademic: Boolean) {
  getAllSchools(isAcademic: $isAcademic) {
    name
    id
  }
}  `,
          variables: { isAcademic },
        }),
      });

      const result = await response.json();

      setSchoolList(result?.data?.getAllSchools);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  const getSchoolIdFromEmail = (email: string): number | null => {
    const domain = email.split("@")[1];
    return domainToSchoolIdMap[domain] || null;
  };

  useEffect(() => {
    const email = localStorage.getItem("email") || "";
    const id = getSchoolIdFromEmail(email);
    // if (id !== null) {
    //   setSchoolID(String(id));
    // }
  }, []);

  // useEffect(() => {
  //   if (schoolID !== null) {
  //     localStorage.setItem("schoolID", schoolID);
  //   }
  // }, [schoolID]);

  const handleComplete = async () => {
    if (!name || !mobile || !password || !email) {
      notyf.error("Please fill all fields!");
      return;
    }

    try {
      const response = await registerCoach({
        variables: {
          input: {
            email: email,
            password: password,
            name: name,
            phone: mobile,
            academicOrSchool: slctOption,
            schoolID: Number(schoolID),
          },
        },
      });

      if (response.data?.registerCoach.status) {
        localStorage.setItem("token", response.data.registerCoach.token);
        localStorage.setItem("name", name);
        localStorage.setItem("mobile", mobile);
        // localStorage.setItem('schoolID', schoolID );
        notyf.success("Registration successful!");
        localStorage.setItem("usertype", "coach");
        window.location.href = "/dashboard";
      } else {
        notyf.error(
          response.data?.registerCoach.message ||
            "Error occurred during registration"
        );
      }
    } catch (err) {
      console.error("Error:", err);
      notyf.error("An error occurred during registration.");
    }
  };

  const InsOption = [
    {
      id: 1,
      name: "Football",
    },
    {
      id: 2,
      name: "cricket",
    },
  ];
  const GenderOption = [
    {
      id: "Boys",
      name: "Boys",
    },
    {
      id: "Girls",
      name: "Girls",
    },
  ];

  return (
    <div className="success-coach-email-container">
      <div className="left-section">
        <div
          className="context"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={TICK}
            height="120px"
            width="120px"
            alt="tick"
            className="tick"
          />
          <br />
          <p className="left-text"> Account Verified Successfully</p>
        </div>

        {/* <div className="area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}

        {showConfetti && (
          <Confetti
            numberOfPieces={700}
            recycle={false}
            gravity={0.05}
            initialVelocityX={2}
            initialVelocityY={10}
            width={window.innerWidth}
            height={window.innerHeight / 2}
          />
        )}
      </div>

      <div className="right-section">
        <div className="title">Complete the Profile</div>

        {/* <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          {option?.map((e) => (
            <div
              key={e.id}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              <input
                type="radio"
                placeholder="select"
                name={e.name} // Ensure all radio buttons have the same `name`
                value={e.name}
                checked={slctOption === e.name}
                onChange={(e) => setSlctOption(e.target.value)}
              />
              <label>{e.name}</label>
            </div>
          ))}
        </div> */}

        <div className="">
          <label htmlFor="name" className="">
            Name
          </label>
          <input
            type="text"
            id="name"
            className="inputboxs"
            placeholder=" "
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="">
          <label htmlFor="mobile" className="">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobile"
            className="inputboxs"
            placeholder=" "
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>

        <div className="">
          <label htmlFor="password" className="">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="inputboxs"
            placeholder=" "
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          {slctOption === "SCHOOL" ? (
            <div>
              <label htmlFor="school-select">School</label>
              <select
                name="school"
                className="inputboxsselect"
                id="school-select"
                onChange={selectSchool}
              >
                <option value="">Select School</option>
                {schoolList?.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <label htmlFor="academic-select">Academic</label>
              <select
                name="school"
                className="inputboxsselect"
                id="academic-select"
                onChange={selectSchool}
              >
                <option value="">Select School</option>
                {schoolList?.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className="input-group">
          <input
            type="email"
            id="email"
            className="input-field"
            placeholder=" "
            value={email}
            disabled
          />
          <label htmlFor="email" className="input-label">
            Email
          </label>
        </div>

        <button
          className="complete-button"
          onClick={handleComplete}
          disabled={loading}
        >
          {loading ? "Completing..." : "Complete"}
        </button>

        {error && (
          <div className="error-message">
            <p className="error-message-text">{error.message}</p>
          </div>
        )}
      </div>

      {/* <img
        src={FOOTERCOMMON}
        alt="Footer"
        className="footer-image-mobile-only"
      /> */}
    </div>
  );
};

export default SuccessCoachEmail;
