import React from "react";

const DashBoard = () => {
  return (
    <div>
      <div className="sidebar-container1">
        <div className="sidebar-menu2"></div>
        <div className="nav-menu1">dashboard</div>
      </div>
    </div>
  );
};

export default DashBoard;
