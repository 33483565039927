import React from 'react'
import "./Tournaments.css"
import logo from "../../assets/logo1.png"
function Tournaments1() {
  return (
    <div>
        <div className="sidebar-container1">

      <div className="sidebar-menu2"></div>

      <div className='nav-menu1'>

<div className='maindiv'>
    <div className='uppperside'>
        <div>
        <span>Tournament <i className='pi pi-chevron-right'></i> </span>
        <span> Invites <i className='pi pi-chevron-right'></i></span>
        <span> TISB U15 Football League 2024</span>
        </div>
        <div className='uphead'>
            <div className='mainuphead'>
               <div> <span className='img-sec'><img src={"logo"} alt="logo" /></span> </div>
               <div>
                <p>The International School Banglore</p>
                <p>TISB U15 Football League 2024</p>
                </div>
            </div>
          <div className='secondaryhead'>
<p> Invited By</p>
<p>Shri Yashraj Shinde</p>
<p> TISB sr. Football Coach</p>
          </div>
        </div>
    </div>
    <div className='left side'>

    </div>
    <div className='rightside'>

    </div>

</div>
      </div>

        </div>
    </div>
  )
}

export default Tournaments1
