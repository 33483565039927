
import { Dialog } from "primereact/dialog";
import React, { useState,useRef } from 'react';

import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';


function Invitation() {

  const [show,setshow] = useState(true)
  return (
    <>
    
    <Dialog
        header="Invitation"
        visible={show}
        style={{ height:"fit-content", width: "450px" }}
        onHide={() => setshow(false)}
        closable={false}
        // className="new-cal-event-dialog"
      >
          <>
            <div className="row d-flex">
              <p>Hi VKSS, GreenWay has been send the invitation to play the Football match. Please accept or reject the invitation. 
              </p>
                          
            </div>
            <div className="row d-flex" style={{float:"inline-end", marginRight:"20px",marginTop:"10px"}}>
              <p>Best Regards Nuava Team</p>
              </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "3rem",
                marginRight:"1rem",
                marginBottom: "1rem",
                gap:"5px"
              }}
              className="buttonContainer"
            >
              <button onClick={()=>{
                setshow(false)
              }} className="button">
                Accept
              </button>
              <button className="button">
                Reject
              </button>
            </div>
          </>
       
      </Dialog>
    </>
  )
}

export default Invitation
