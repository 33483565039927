import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SideBar() {
  const navigation = useNavigate();

  const [activeLink, setActiveLink] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Get the last segment from the pathname
    const pathSegments = location.pathname.split("/");
    const currentLink = pathSegments[pathSegments.length - 1];
    setActiveLink(currentLink);
  }, [location]);

  return (
    <div>
      <div className="sidebar-menu1">
        <p className="mainheading"> Q U I C K S U M M A R Y</p>
        <p
          onClick={() => {
            navigation("/dashboard");
          }}
          className={
            activeLink == "dashboard" ? "subheading active-menu" : "subheading "
          }
        >
          <span>
            <i className="pi pi-microsoft"></i>
          </span>
          <span> Dashboard</span>
        </p>
        <p className="subheading">
          {" "}
          <span>
            <i className="pi pi-bell"></i>
          </span>
          <span>Activity</span>
        </p>
        {/* .events */}
        <p className="mainheading"> E V E N T S</p>
        <p
          onClick={() => {
            navigation("/dashboard/calender");
          }}
          className={
            activeLink == "calender" ? "subheading active-menu" : "subheading "
          }
        >
          <span>
            <i className="pi pi-calendar"></i>
          </span>
          <span> Calendar</span>
        </p>
        <p className="mainheading"> T O U R N A M E N T S</p>
        <p
          onClick={() => {
            navigation("/dashboard/tournament");
          }}
          className={
            activeLink == "tournament1"
              ? "subheading active-menu"
              : "subheading "
          }
        >
          <span>
            <i className="pi pi-trophy"></i>
          </span>
          <span> Tournaments</span>
        </p>
        <p className="subheading">
          <span>
            <i className="pi pi-credit-card"></i>
          </span>
          <span> Manage Payments</span>
        </p>
        {/*  */}
        <p className="mainheading"> C O M M U N I C A T I O N </p>
        <p className="subheading">
          <span>
            <i className="pi pi-comment"></i>
          </span>
          <span> Messages</span>
        </p>
        <p className="subheading">
          <span>
            <i className="pi pi-envelope"></i>
          </span>
          <span> Parent Reports</span>
        </p>
        {/*  */}
        <p className="mainheading"> S T U D E N T S H U B </p>
        <p className="subheading">
          <span>
            <i className="pi pi-briefcase"></i>
          </span>
          <span>Performance Track</span>
        </p>
        <p className="subheading">
          <span>
            <i className="pi pi-user-plus"></i>
          </span>
          <span>Register</span>
        </p>
      </div>
    </div>
  );
}

export default SideBar;
