import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import {
  gql,
  useMutation,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Notyf } from "notyf";
import FOOTERCOMMON from "../../assets/FOOTERCOMMON.png";
import "./SuccessStudentEmail.css";
import TICK from "../../assets/TICK.png";

// Initialize Notyf for notifications
const notyf = new Notyf({
  duration: 2000,
  position: {
    x: "right",
    y: "top",
  },
  types: [
    {
      type: "warning",
      background: "orange",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "warning",
      },
    },
    {
      type: "success",
      background: "green",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "check",
      },
    },
    {
      type: "error",
      background: "red",
      duration: 2000,
      dismissible: false,
    },
  ],
});

const REGISTER_STUDENT = gql`
  mutation RegisterStudent($input: RegisterStudentInput!) {
    registerStudent(input: $input) {
      message
      status
      token
    }
  }
`;

// Create an Apollo client instance with the temporary token in the headers
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("tempToken");
  return {
    headers: {
      ...headers,
      Authorization: token ? `jwt ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

interface SchoolOption {
  name: string;
}

const SuccessStudentEmail: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  // State for user inputs
  const [name, setName] = useState("");
  const [schoolID, setSchoolID] = useState("");
  const [grade, setGrade] = useState("");
  const [age, setAge] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") ?? "");
  const [slctOption, setSlctOption] = useState(
    localStorage.getItem("schoolAcademic")
  );
  const [selctedIns, setSelectedIns] = useState<string>("");
  const [selctedGender, setSelectedGender] = useState<string>("");
  const [schoolList, setSchoolList] = useState([]);

  console.log("schoolList", schoolList);
  const [registerStudent, { loading, error }] = useMutation(REGISTER_STUDENT, {
    client,
  });

  const selectSchool = (e: any) => {
    console.log("select school", e.target.value);

    setSchoolID(e.target.value);
  };

  const fetchSchools = async () => {
    const token = localStorage.getItem("token");
    const isAcademic = slctOption == "ACADEMIC";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          query: ` query GetAllSchools($isAcademic: Boolean) {
  getAllSchools(isAcademic: $isAcademic) {
    name
    id
  }
}  `,
          variables: { isAcademic },
        }),
      });

      const result = await response.json();

      setSchoolList(result?.data?.getAllSchools);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  useEffect(() => {
    setShowConfetti(true);
  }, []);

  const handleComplete = async () => {
    if (!name || !grade || !age || !password || !email || !selctedGender) {
      notyf.error("Please fill all fields!");
      return;
    }

    try {
      const response = await registerStudent({
        variables: {
          input: {
            email: email,
            password: password,
            name: name,
            // schoolID: parseInt(schoolID, 10),
            grade: grade,
            age: parseInt(age, 10),
            academicOrSchool: slctOption,
            schoolID: Number(schoolID),
            gender: selctedGender,
          },
        },
      });

      if (response.data?.registerStudent.status) {
        localStorage.setItem("token", response.data.registerStudent.token);
        localStorage.setItem("name", name);
        // localStorage.setItem('schoolID', schoolID);
        localStorage.setItem("grade", grade);
        localStorage.setItem("age", age.toString());
        localStorage.setItem("usertype", "student");
        // localStorage.setItem("academicOrSchool", slctOption);
        localStorage.setItem("intrest", selctedIns);
        localStorage.setItem("gender", selctedGender);

        notyf.success("Registration successful!");
        window.location.href = "/dashboard";
      } else {
        notyf.error(
          response.data?.registerStudent.message ||
            "Error occurred during registration"
        );
      }
    } catch (err) {
      console.error("Error:", err);
      notyf.error("An error occurred during registration.");
    }
  };

  interface Option {
    id: number;
    name: string;
  }

  const option: Option[] = [
    {
      id: 1,
      name: "School",
    },
    {
      id: 2,
      name: "Academic",
    },
  ];

  const InsOption = [
    {
      id: 1,
      name: "Football",
    },
    {
      id: 2,
      name: "cricket",
    },
  ];
  const GenderOption = [
    {
      id: "Boys",
      name: "Boys",
    },
    {
      id: "Girls",
      name: "Girls",
    },
  ];

  return (
    <div className="success-coach-email-container">
      <div className="left-section">
        <div
          className="context"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={TICK}
            height="120px"
            width="120px"
            alt="tick"
            className="tick"
          />
          <br />
          <p className="left-text"> Account Verified Successfully</p>
        </div>

        {showConfetti && (
          <Confetti
            numberOfPieces={700}
            recycle={false}
            gravity={0.05}
            initialVelocityX={2}
            initialVelocityY={10}
            width={window.innerWidth}
            height={window.innerHeight / 2}
          />
        )}
      </div>

      <div className="right-section">
        <div className="title">Complete the Profile</div>
        {/* <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          {option?.map((e) => (
            <div
              key={e.id}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              <input
                type="radio"
                placeholder="select"
                name={e.name} // Ensure all radio buttons have the same `name`
                value={e.name}
                checked={slctOption === e.name}
                onChange={(e) => setSlctOption(e.target.value)}
              />
              <label>{e.name}</label>
            </div>
          ))}
        </div> */}

        <div className="">
          <input
            type="text"
            id="name"
            className="input-field"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* <div className="input-group">
          <input
            type="text"
            id="schoolID"
            className="input-field"
            placeholder="School ID"
            value={schoolID}
            onChange={(e) => setSchoolID(e.target.value)}
          />
        </div> */}

        <div className="">
          <input
            type="text"
            id="grade"
            className="input-field"
            placeholder="Grade"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
          />
        </div>

        <div className="">
          <input
            type="text"
            id="age"
            className="input-field"
            placeholder="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>

        <div className="">
          <input
            type="password"
            id="password"
            className="input-field"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          {slctOption === "SCHOOL" ? (
            <div>
              <label htmlFor="school-select">School</label>
              <select
                name="school"
                className="inputboxsselect"
                id="school-select"
                onChange={selectSchool}
              >
                <option value="">Select School</option>
                {schoolList?.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <label htmlFor="academic-select">Academic</label>
              <select
                name="school"
                className="inputboxsselect"
                id="academic-select"
                onChange={selectSchool}
              >
                <option value="">Select School</option>
                {schoolList?.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className="">
          <input
            type="email"
            id="email"
            className="input-field"
            placeholder="Email"
            value={email}
            disabled
          />
        </div>
        {/* <div>
          <label htmlFor="select">Sports</label>
          <select
            title="select"
            className="input-field"
            defaultValue={selctedIns}
            onChange={(e) => setSelectedIns(e.target.value)}
          >
            <option value="" disabled>
              -- Select an option --
            </option>
            {InsOption.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div> */}

        <div>
          <label htmlFor="select">Gender</label>
          <select
            title="select"
            className="input-field"
            defaultValue={selctedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
          >
            <option value="" disabled>
              -- Select an option --
            </option>
            {GenderOption.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <button
          className="complete-button"
          onClick={handleComplete}
          disabled={loading}
        >
          {loading ? "Completing..." : "Complete"}
        </button>

        {error && (
          <div className="error-message">
            <p className="error-message-text">{error.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessStudentEmail;
