// src/App.tsx
// @ts-ignore
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Onboarding from "./components/Onboarding";
import SendCoachEmailOTP from "./components/Coach/SendCoachEmailOTP";
import SuccessCoachEmail from "./components/Coach/SuccessCoachEmail";
// import Dashboad from "./components/Dashboard";
import SendStudentEmailOTP from "./components/Students/SendStudentEmailOTP";
import SuccessStudentEmail from "./components/Students/SuccessStudentEmail";
import CoachLogin from "./components/Coach/CoachLogin";
import StudentLogin from "./components/Students/StudentLogin";
import Dashboard from "./components/Dashboard";
import NewTournament from "./components/NewTournament";
import FootballDashboard from "./components/Football/FootballDashboard";
import Calender from "./components/Calender";
import URLPage from "./components/URLPage";
import IHMatches from "./components/IH Matches/IHMatches";
import CreateMatch from "./components/IH Matches/CreateMatch";
import About from "./components/About";
import Contact from "./components/Contact";
import Brackets from "./components/Brackets.jsx";
import Profile from "./components/Profile";
import Matches from "./components/Football/Matches";
import MatchesMain from "./components/Matches";
import NoAuthBrackets from "./components/NoAuthBrackets";
import Result from "./components/Result";
import Settings from "./components/Settings";
import IHResult from "./components/IH Matches/IHResult";
import CricketMatches from "./components/Cricket/CricketMatches";
import HockeyMatches from "./components/Hockey/HockeyMatches";
import TennisMatches from "./components/Tennis/TennisMatches";
import Invitation from "./components/Invitation/Invitation";
import Calendar1 from "./components/Calendar1/Calendar1";
import "./App.css";
import Tournaments1 from "./components/Tournaments/Tournaments1";

// Set up Apollo Client
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_BASE_URL}`,

  cache: new InMemoryCache(),
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Onboarding />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* COACH ROUTES */}
          {/* <Route path="/send-coach-email-otp" element={<SendCoachEmailOTP />} /> */}
          <Route path="/coach/register" element={<SendCoachEmailOTP />} />
          <Route path="/coach/success" element={<SuccessCoachEmail />} />
          {/* <Route path="/success-coach-email" element={<SuccessCoachEmail />} /> */}
          <Route path="/coach/login" element={<CoachLogin />} />

          {/* STUDENTS ROUTES */}
          <Route path="/student/register" element={<SendStudentEmailOTP />} />
          <Route path="/student/success" element={<SuccessStudentEmail />} />
          <Route path="/student/login" element={<StudentLogin />} />
          {/* COMMON ROUTES */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/dashboard1" element={<Calendar1 />} /> */}
          <Route path="/forgotpassword" element={<h1>Forgot Password</h1>} />
          <Route path="/resetpassword" element={<h1>Reset Password</h1>} />
          <Route path="/tournament/create" element={<NewTournament />} />
          <Route path="/dashboard/football" element={<FootballDashboard />} />
          <Route path="/dashboard/tennis" element={<TennisMatches />} />
          <Route path="/dashboard/cricket" element={<CricketMatches />} />
          <Route path="/dashboard/hockey" element={<HockeyMatches />} />
          <Route
            path="/dashboard/inter-house-matches"
            element={<IHMatches />}
          />
          {/* <Route path="/calender" element={<Calendar1 />} /> */}
          <Route path="/calender" element={<Calender />} />
          <Route path="/dashboard/calender" element={<Calendar1 />} />
          <Route path="/dashboard/tournament" element={<Calendar1 />} />
          <Route path="/Invitation" element={<Invitation />} />
          <Route path="/brackets" element={<Brackets />} />
          <Route path="/create-match" element={<CreateMatch />} />
          <Route path="/urls" element={<URLPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/football/matches" element={<Matches />} />
          <Route path="/matches" element={<MatchesMain />} />
          <Route
            path="/all/football/brackets/:id"
            element={<NoAuthBrackets />}
          />
          <Route path="/result" element={<Result />} />
          <Route path="*" element={<h1>404 | Page not found</h1>} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/inter-house-result" element={<IHResult />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
