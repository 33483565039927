import React, { useEffect, useRef, useState } from "react";
import "./Navbar2.css";
import "primeicons/primeicons.css";
import logo from "../../assets/logo1.png";
import { InputText } from "primereact/inputtext";
import profile from "../../assets/AVATAR/3.png";
import { Empty, notification } from "antd";
import { useNavigate } from "react-router-dom";

import { ApolloClient, createHttpLink, gql, useMutation } from "@apollo/client";
import { Toast } from "primereact/toast";

const updateAproval = gql`
  mutation UpdateInterestApprovalStatus(
    $studentId: Int!
    $approvalStatus: String!
  ) {
    updateInterestApprovalStatus(
      studentId: $studentId
      approvalStatus: $approvalStatus
    ) {
      intrestApprovalStatus
      message
      status
    }
  }
`;

interface Student {
  id: number;
  name: string;
  email: string;
  intrest: string;
  typeofSport: any;
}

interface NotificationResponse {
  status: string;
  students: Student[];
}

interface FetchEventsResponse {
  data: {
    getPendingInterestStudents: NotificationResponse;
  };
}

function Navbar2() {
  const [value, setValue] = useState<string>("");
  const [notificationData, setNotificationData] = useState<Student[]>([]);
  const [notificationStatus, setNotificationState] = useState<boolean>(false);
  const [profileStatus, setprofileStatus] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [usertypeData, setusertypeData] = useState(
    localStorage.getItem("usertype")
  );
  const toast = useRef<any>(null);

  const showToast = (severity: string, summary: string, detail: string) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail, life: 3000 });
    }
  };

  const [nameData, setnameData] = useState(localStorage.getItem("name"));
  const [updateStatuss, setUpdateStatus] = useState(false);

  const [updateAprovals, { loading: updateAprovalData }] =
    useMutation(updateAproval);

  const updateStatus = async (item: any, status: any) => {
    console.log("updateStatuss", item, status);

    const token = localStorage.getItem("token");
    try {
      const response = await updateAprovals({
        variables: {
          studentId: Number(item),
          approvalStatus: status,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      console.log("response", response);

      setUpdateStatus((prev) => !prev);

      if (response?.data?.updateInterestApprovalStatus?.status) {
        showToast(
          "success",
          "Updated",
          response?.data?.updateInterestApprovalStatus?.message
        );
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    PendingInterestStudents();
  }, [updateStatuss]);

  const navigation = useNavigate();

  const PendingInterestStudents = async () => {
    const schoolId = localStorage.getItem("schoolID");

    const query = `
      query GetPendingInterestStudents($schoolId: Int!) {
        getPendingInterestStudents(schoolId: $schoolId) {
          status
          students {
            id
            name
            email
            intrest
          }
        }
      }
    `;

    const token = localStorage.getItem("token");

    if (!token) {
      alert("Authentication token is missing");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query,
          variables: { schoolId: Number(schoolId) },
        }),
      });

      const result: FetchEventsResponse = await response.json();
      console.log(
        "result student data",
        result?.data?.getPendingInterestStudents?.students
      );

      setNotificationData(result?.data?.getPendingInterestStudents?.students);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  return (
    <div className="nav-container">
      <div className="nav-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="nav-menu" style={{ padding: "0px 1.3rem" }}>
        <div style={{ position: "relative" }} className="search">
          <InputText
            className="nav-search"
            value={value}
            placeholder="search student, tournaments..."
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="magnifier">
            <i className="pi pi-search" style={{ color: "#9CA3AF" }}></i>
          </div>
        </div>
        <div className="menu-items">
          <div style={{ position: "relative" }}>
            <span
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => setNotificationState((prev) => !prev)}
              className="noti-circle"
            >
              <i className="pi pi-bell" style={{ fontWeight: "bold" }}></i>
            </span>
            {notificationStatus && (
              <div className="notificationBox">
                <div className="notificationContainer">
                  <div className="notificationheader">
                    <span>Notification</span>
                    <span onClick={() => setNotificationState(false)}>
                      <i className="pi pi-times" style={{ color: "green" }}></i>
                    </span>
                  </div>

                  <div className="notificationBody">
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        padding: "0px 1rem",
                        margin: "8px 0px",
                      }}
                    >
                      <button
                        onClick={() => setActiveIndex(0)}
                        className={
                          activeIndex === 0 ? "tab-active" : "tab-button"
                        }
                      >
                        All
                      </button>
                      <button
                        onClick={() => setActiveIndex(1)}
                        className={
                          activeIndex === 1 ? "tab-active" : "tab-button"
                        }
                      >
                        Invites
                      </button>
                      <button
                        onClick={() => setActiveIndex(2)}
                        className={
                          activeIndex === 2 ? "tab-active" : "tab-button"
                        }
                      >
                        Inbox
                      </button>
                    </div>

                    <div>
                      {activeIndex === 0 && (
                        <div
                          style={{ maxHeight: "350px", overflowY: "scroll" }}
                        >
                          {usertypeData == "coach" ? (
                            <>
                              {notificationData?.map(
                                (item: any, index: any) => {
                                  // const interest = JSON.parse(item.intrest);

                                  console.log("item", item);

                                  return (
                                    <>
                                      <div
                                        className="notificationbg"
                                        style={{ display: "flex", gap: "4px" }}
                                        key={item.id}
                                      >
                                        <span className="notificationProfiles">
                                          <span className="profilePhoto">
                                            {item?.name
                                              ?.charAt(0)
                                              .toUpperCase()}
                                          </span>
                                        </span>
                                        <span className="notificationMessage">
                                          <div>
                                            <p className="notificationheading">
                                              {item.name}
                                            </p>
                                            <p className="notificationPara">
                                              {item?.email}
                                            </p>
                                            <p className="notificationParasub">
                                              {item?.intrest}
                                            </p>
                                          </div>
                                          <div className="notificationParasub">
                                            <div style={{ textAlign: "end" }}>
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  updateStatus(
                                                    item?.id,
                                                    "Appoved"
                                                  )
                                                }
                                                className="tab-active-accept"
                                              >
                                                Approve
                                              </p>
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  updateStatus(
                                                    item?.id,
                                                    "Rejected"
                                                  )
                                                }
                                                className="tab-active-reject"
                                              >
                                                Reject
                                              </p>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <Empty />
                          )}
                        </div>
                      )}
                      {activeIndex === 1 && (
                        <div>
                          <Empty />
                        </div>
                      )}
                      {activeIndex === 2 && (
                        <div>
                          {" "}
                          <Empty />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="name-coach">
            <div>
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>{nameData}</p>
              <p
                style={{
                  fontWeight: "semi-bold",
                  fontSize: "12px",
                  color: "#B9C3F9",
                  textAlign: "end",
                }}
              >
                {usertypeData}
              </p>
            </div>

            <div style={{ position: "relative", cursor: "pointer" }}>
              <div
                onClick={() => {
                  setprofileStatus((prev) => !prev);
                }}
              >
                <span className="profile">
                  <img src={profile} alt="profile" />
                </span>
              </div>

              {profileStatus ? (
                <div className="profileData">
                  <div
                    onClick={() => {
                      navigation("/profile");
                      setprofileStatus((prev) => !prev);
                    }}
                    className="profiles"
                  >
                    <i className="pi pi-user" style={{ fontSize: "1rem" }}></i>{" "}
                    Profile
                  </div>
                  <hr />
                  <div
                    className="profiles"
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = "/";
                      setprofileStatus((prev) => !prev);
                    }}
                  >
                    <i
                      className="pi pi-sign-out"
                      style={{ fontSize: "1rem" }}
                    ></i>{" "}
                    Log Out
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <span className="noti-circle2">
            <i
              className="pi pi-chevron-down"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            ></i>
          </span>
        </div>
      </div>
      <Toast ref={toast} position="top-right" />
    </div>
  );
}

export default Navbar2;
