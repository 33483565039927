import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "./Navbar";
import { ApolloClient, createHttpLink, gql, useMutation } from "@apollo/client";

import BANNER_MAIN from "../assets/BANNER_MAIN.png";
import Footer from "../components/Footer";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Card,
  CardContent,
  Typography,
  Alert,
  Checkbox,
  Switch,
} from "@mui/material";
import { Skeleton } from "primereact/skeleton";
import "primeicons/primeicons.css";
import "../styles/Dashboard.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { set } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";

const create_Attendance = gql`
  mutation CreateAttendance($input: AttendanceInput!) {
    createAttendance(input: $input) {
      status
      message
      attendance {
        absent
        present
      }
    }
  }
`;

// Define the GraphQL queries as strings
const CREATE_ATTENDANCE_MUTATION = `
  mutation CreateAttendance($input: AttendanceInput!) {
    createAttendance(input: $input) {
      status
      message
      attendance {
        id
        # Add any other fields you need here
      }
    }
  }
`;

const FETCH_ATTENDANCE_DETAILS_QUERY = `
  query GetAttendanceByEventId {
    getAttendanceByEventId {
      attendance{
      absent
      present
      }
      message
    }
  }
`;

const localizer = momentLocalizer(moment);

interface Coach {
  id: string;
  name: string;
  email: string;
  phone: string;
  schoolID: string;
}

interface Student {
  age: number;
  grade: string;
  email: string;
  id: string;
  name: string;
  schoolID: number;
  present: boolean;
  absent: boolean;
}

interface MyDataTableProps {
  team2DataPlayer: Student[];
}

interface Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  isAllDay: boolean;
  details: string;
  typeOfEvent: string;
}

const Dashboard: React.FC = () => {
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [loadingCoaches, setLoadingCoaches] = useState<boolean>(true);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
  const [coachError, setCoachError] = useState<string | null>(null);
  const [eventError, setEventError] = useState<string | null>(null);
  const [data, setData] = useState(null);
  const [eventDetailsVisible, setEventDetailsVisible] = useState(false);
  const [eventDetailsVisibleA, setEventDetailsVisibleA] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [startTime, setStartTime] = useState<moment.Moment | null>(moment());
  const [endTime, setEndTime] = useState<moment.Moment | null>(moment());
  const [isAllDay, setIsAllDay] = useState<boolean>(false);
  const [eventType, setEventType] = useState<string>("Normal Event");
  const [moderatorAccess, setModeratorAccess] = useState<boolean>(false);
  const [selectedStudents, setSelectedStudents] = useState<Student[]>([]);
  const [teams, setTeams] = useState<{ name: string; students: Student[] }[]>(
    []
  );

  const eventId = selectedEvent?.id;

  const [team1DataPlayer, setTeam1DataPlayer] = useState([]);
  const [team2DataPlayer, setTeam2DataPlayer] = useState([]);
  const [team1Data, setTeam1Data] = useState([]);
  const [team2Data, setTeam2Data] = useState([]);

  const [createAttendance, { loading }] = useMutation(create_Attendance);

  const toast = useRef<any>(null);

  const [team1DataState, setTeam1DataState] = useState<Student[]>([]);
  const [team2DataState, setTeam2DataState] = useState<Student[]>([]);

  console.log("team2DataState", team2DataState);

  const [studentsData, setStudentsData] = useState<Student[]>([]);
  const [presentIds, setPresentIds] = useState<string[]>([]);
  const [absentIds, setAbsentIds] = useState<string[]>([]);

  const [attendances, setAttendances] = useState([]);

  const showToast = (severity: string, summary: string, detail: string) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail, life: 3000 });
    }
  };

  // Initialize data with all students marked as absent
  useEffect(() => {
    const initializeData = (team1Data: Student[], team2Data: Student[]) => {
      const combinedData = [...team1Data, ...team2Data].map((student) => ({
        ...student,
        present: false,
        absent: true,
      }));

      return combinedData;
    };

    const initializedData = initializeData(team1DataPlayer, team2DataPlayer);

    setStudentsData(initializedData);
    setAbsentIds(initializedData.map((student) => student.id));
    setPresentIds([]);
  }, [team1DataPlayer, team2DataPlayer]);

  // Update student status and ensure only one checkbox is checked at a time
  // const updateStudentStatus = useCallback(
  //   (studentId: string, field: "present" | "absent") => {
  //     const updatedData = studentsData.map((student) => {
  //       if (student.id === studentId) {
  //         const newStudent = { ...student };
  //         if (field === "present") {
  //           newStudent.present = !newStudent.present;
  //           newStudent.absent = !newStudent.present; // If present is true, absent should be false
  //         } else {
  //           newStudent.absent = !newStudent.absent;
  //           newStudent.present = !newStudent.absent; // If absent is true, present should be false
  //         }

  //         return newStudent;
  //       }
  //       return student;
  //     });

  //     // Update state with new data
  //     setStudentsData(updatedData);

  //     // Update present and absent IDs
  //     const newPresentIds = updatedData
  //       .filter((student) => student.present)
  //       .map((student) => student.id);
  //     const newAbsentIds = updatedData
  //       .filter((student) => student.absent)
  //       .map((student) => student.id);

  //     setPresentIds(newPresentIds);
  //     setAbsentIds(newAbsentIds);
  //   },
  //   [studentsData]
  // );

  const saveAttendance = async (e: React.FormEvent) => {
    const token = localStorage.getItem("token");

    e.preventDefault();

    const presentData = presentIds.map((id) => parseInt(id, 10));
    const absentData = absentIds.map((id) => parseInt(id, 10));

    try {
      // Replace `absentIds`, `presentIds`, and `eventId` with your actual data
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure `token` is defined
        },
        body: JSON.stringify({
          query: CREATE_ATTENDANCE_MUTATION,
          variables: {
            input: {
              absent: absentData,
              present: presentData,
              eventID: eventId,
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      const message = result?.data?.createAttendance?.message;
      showToast("success", "Attendance Created", message);
      setEventDetailsVisibleA(false);

      // Fetching attendance details
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ query: FETCH_ATTENDANCE_DETAILS_QUERY }),
        }
      );

      if (!fetchResponse.ok) {
        throw new Error(`HTTP error! status: ${fetchResponse.status}`);
      }

      const fetchResult = await fetchResponse.json();
      console.log("Attendance details:", fetchResult);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateStudentStatus = (id: string, field: "present" | "absent") => {
    const updatedData = team2DataState.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          present: field === "present",
          absent: field === "absent",
        };
      }
      return val;
    });

    const updatedPresentIds = updatedData
      .filter((student) => student.present)
      .map((student) => student.id);
    const updatedAbsentIds = updatedData
      .filter((student) => student.absent)
      .map((student) => student.id);

    // Update the state with the new list of present IDs
    setPresentIds(updatedPresentIds);
    setAbsentIds(updatedAbsentIds);

    setTeam2DataState(updatedData);
  };

  useEffect(() => {
    const initializeTeamData = (data: Student[]) => {
      return data.map((student) => ({
        ...student,
        present: true,
        absent: false,
      }));
    };

    setTeam1DataState(initializeTeamData(team1DataPlayer));
    setTeam2DataState(initializeTeamData(team2DataPlayer));
  }, [team1DataPlayer, team2DataPlayer]);

  // EVENT DETIALS DIALOG
  const formatDuration = (start: Date, end: Date) => {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return `${duration.asHours().toFixed(1)} hours`;
  };

  const handleEventSelect = (event: any) => {
    setSelectedEvent(event);
    setTitle(event.title);
    setDescription(event.description);
    setStartDate(new Date(event.start));
    setEndDate(new Date(event.end));
    setStartTime(moment(event.start));
    setEndTime(moment(event.end));
    setIsAllDay(event.allDay);
    setEventType(event.typeOfEvent);
    setEventDetailsVisible(true);
  };

  useEffect(() => {
    localStorage.setItem("selectedSport", "Overview");
  }, []);

  const fetchGraphQLData = async (query: string): Promise<any> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No JWT token found");
    }

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `jwt ${token}`,
      },
      body: JSON.stringify({ query }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  const fetchAttendance = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          query: `query {
            getAttendanceByEventId(eventID: ${eventId}) {
              attendance {
                id
                absent
                present
                eventID
              }
            }
          }`,
        }),
      });

      const result = await response.json();

      // Set the attendances state
      setAttendances(result?.data?.getAttendanceByEventId?.attendance);

      // Get the absent data
      const absentData = result?.data?.getAttendanceByEventId?.attendance
        ?.filter((entry: { absent: any }) => entry.absent)
        .map((entry: { id: any }) => entry.id);

      console.log("sdfsdfsdf team2DataState", team2DataState);
      console.log("sdfsdfsdf absentData", absentData);

      const updatedData = team2DataState.map((entry) => {
        if (absentData.includes(Number(entry.id))) {
          return { ...entry, present: false, absent: true };
        }
        return entry;
      });

      // // Update the team2DataState based on absentData
      // const updatedData = team2DataState.map((entry) => {
      //   if (absentData.includes(Number(entry.id))) {
      //     return { ...entry, present: false, absent: true };
      //   }
      //   return entry;
      // });

      // Assuming you want to update the state after mapping
      setTeam2DataState(updatedData);

      console.log(
        "result attendance",
        result?.data?.getAttendanceByEventId?.attendance
      );
    } catch (error) {
      console.error("Error fetching teams:", error);
      showToast("error", "Error", "Error fetching teams");
    }
  };

  const attendance = async (details: any) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No JWT token found");
    }

    console.log("details", details);

    const parsedDetails = JSON.parse(details.details);
    const fixtureID = parsedDetails.fixtureID;

    const query = `
     query  {
  getFixtureResultById(fixtureId: ${fixtureID}) {
   
    teamParticipationId1
    teamParticipationId2
  
}
}
    `;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      console.log("result 1 ", result);

      const team1Id1 = result?.data?.getFixtureResultById?.teamParticipationId1;
      const team1Id2 = result?.data?.getFixtureResultById?.teamParticipationId2;

      const query1 = `
     query {
  getTeamWithPlayers(teamId: ${team1Id1}) {

    name
    coachID
    typeOfSport
    players {
  id
      name
    }
  }
}
     `;
      const query2 = `
     query {
  getTeamWithPlayers(teamId: ${team1Id2}) {

    name
    coachID
    typeOfSport
    players {
  id
      name
    }
  }
}
     `;

      const response1 = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query: query1 }),
      });

      if (!response1.ok) {
        throw new Error(`HTTP error! status: ${response1.status}`);
      }

      const result1 = await response1.json();

      console.log("result1", result1?.data);

      setTeam1DataPlayer(
        result1?.data ? result1?.data?.getTeamWithPlayers?.players : []
      );
      setTeam1Data(
        result1?.data ? result1?.data?.getTeamWithPlayers?.name : []
      );

      const response2 = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query: query2 }),
      });

      if (!response1.ok) {
        throw new Error(`HTTP error! status: ${response2.status}`);
      }

      const result2 = await response2.json();

      setTeam2Data(
        result1?.data ? result2?.data?.getTeamWithPlayers?.name : []
      );

      setTeam2DataPlayer(
        result1?.data ? result2?.data?.getTeamWithPlayers?.players : []
      );

      fetchAttendance();

      setEventDetailsVisibleA(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const eventDetailsHeader = (
  //   <div className="flex justify-content-between align-items-center">
  //     <span className="text-xl font-bold">Event Details</span>
  //     <div>
  //       {/* <Button
  //         icon="pi pi-pencil"
  //         className="p-button-text p-button-sm"
  //         style={{ color: "grey" }}
  //         // onClick={handleEditButtonClick}
  //       />
  //       <Button
  //         icon="pi pi-trash"
  //         className="p-button-text p-button-sm p-button-danger"
  //         style={{ color: "red" }}
  //         // onClick={handleDeleteEvent}
  //       /> */}
  //       {/* <Button icon="pi pi-times" className="p-button-text p-button-sm" style={{color:'grey'}} onClick={() => setEventDetailsVisible(false)} /> */}

  //     </div>
  //   </div>
  // );

  useEffect(() => {
    const userType = localStorage.getItem("usertype");

    const fetchData = async () => {
      try {
        let query;
        if (userType === "coach") {
          query = `
            query Coach {
              coach {
                id
                name
                email
                phone
                schoolID
                schoolName
                typeOfSport
              }
            }
          `;
        } else if (userType === "student") {
          query = `
            query Student {
              student {
                id
                email
                name
                schoolID
                grade
                age
                moderatorAccess
                intrestApprovalStatus
                intrest
              }
            }
          `;
        } else {
          console.error("Invalid user type");
          throw new Error("Invalid user type");
        }

        const data = await fetchGraphQLData(query);
        if (userType === "student") {
          const {
            id,
            email,
            name,
            schoolID,
            grade,
            age,
            moderatorAccess,
            intrestApprovalStatus,
            intrest,
          } = data.data.student;
          localStorage.setItem("id", id.toString());
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("schoolID", schoolID.toString());
          localStorage.setItem("grade", grade);
          localStorage.setItem("age", age.toString());
          localStorage.setItem("moderatorAccess", moderatorAccess.toString());
          localStorage.setItem(
            "intrestApprovalStatus",
            intrestApprovalStatus.toString()
          );
          localStorage.setItem("intrest", intrest.toString());
          setModeratorAccess(moderatorAccess);
        } else if (userType === "coach") {
          const { id, email, name, phone, schoolID, typeOfSport } =
            data.data.coach;

          localStorage.setItem("id", id.toString());
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("typeOfSport", JSON.stringify(typeOfSport));
          localStorage.setItem("phone", phone);
          localStorage.setItem("schoolID", schoolID.toString());
          localStorage.setItem("school", data.data.coach.schoolName);
        }

        // Fetch all coaches regardless of user type
        const allCoachesQuery = `
          query GetAllCoaches {
            getAllCoaches {
              id
              name
              email
              phone
              schoolID
            }
          }
        `;
        const allCoachesData = await fetchGraphQLData(allCoachesQuery);
        setCoaches(allCoachesData.data.getAllCoaches || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const filteredStudents = students.filter((student: Student) =>
  //   student.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    const fetchCoaches = async () => {
      setLoadingCoaches(true);
      try {
        const coachQuery = `
          query Coach {
            coach {
            id
            name
            email
            phone
            schoolID
            }
          }
        `;
        const coachData = await fetchGraphQLData(coachQuery);
        if (coachData.errors) {
          throw new Error(coachData.errors[0].message);
        }
        setCoaches(coachData.data.coach || []);
      } catch (error) {
        console.error("Error fetching coaches:", error);
        setCoachError(
          error instanceof Error ? error.message : "An unknown error occurred"
        );
      } finally {
        setLoadingCoaches(false);
      }
    };

    const fetchEvents = async () => {
      setLoadingEvents(true);
      try {
        const eventQuery = `
          query Event {
            getAllEvents {
              id
              title
              startDate
              endDate
              isAllDay
              details
              typeOfEvent
            }
          }
        `;
        const eventData = await fetchGraphQLData(eventQuery);

        if (eventData.errors) {
          throw new Error(eventData.errors[0].message);
        }
        const formattedEvents = (eventData.data.getAllEvents || []).map(
          (event: any) => ({
            ...event,
            start: new Date(parseInt(event.startDate)),
            end: new Date(parseInt(event.endDate)),
          })
        );
        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
        setEventError(
          error instanceof Error ? error.message : "An unknown error occurred"
        );
      } finally {
        setLoadingEvents(false);
      }
    };

    // fetchGraphQLData();
    fetchCoaches();
    fetchEvents();
  }, []);

  const eventStyleGetter = (event: Event) => {
    let backgroundColor = "#3174ad";
    switch (event.typeOfEvent) {
      case "Fixture Event":
        backgroundColor = "#8fc9ff";
        break;
      case "Inter House Event":
        backgroundColor = "#ffc8dd";
        break;
      case "Normal Event":
        backgroundColor = "#98f5e1";
        break;
    }
    return { style: { backgroundColor } };
  };

  const CustomToolbar = () => null;

  const renderCalendarSkeleton = () => (
    <div
      style={{
        height: 500,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} height="80px" />
      ))}
    </div>
  );

  const renderCoachSkeleton = () => (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
      {[...Array(6)].map((_, index) => (
        <Card key={index} style={{ width: 250, marginBottom: 20 }}>
          <CardContent>
            <Skeleton width="60%" height="24px" />
            <Skeleton width="80%" />
            <Skeleton width="70%" />
            <Skeleton width="50%" />
          </CardContent>
        </Card>
      ))}
    </div>
  );

  return (
    <div>
      <Navbar buttontext="Create Tournament / Match" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "4rem",
        }}
      >
        {/* <img
          src={BANNER_MAIN}
          alt="Football"
          height="300"
          width="900"
          style={{ borderRadius: "15px" }}
        /> */}
      </div>
      <div>
        {/* <Typography
          variant="h5"
          style={{
            textAlign: "left",
            marginBottom: "20px",
            color: "grey",
            marginLeft:'80px',
           
          }}
        >
          Dashboard
        </Typography> */}
      </div>
      {(coachError || eventError) && (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
          {coachError && <div>Error loading coaches: {coachError}</div>}
          {eventError && <div>Error loading events: {eventError}</div>}
        </Alert>
      )}
      <div className="dash-main-container">
        <div style={{ margin: "21px" }} className="calender-dash">
          {loadingEvents ? (
            renderCalendarSkeleton()
          ) : (
            <BigCalendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              views={["month"]}
              toolbar={false}
              selectable={false}
              components={{
                toolbar: CustomToolbar,
              }}
              eventPropGetter={eventStyleGetter}
              tooltipAccessor={(event: { title: any }) => event.title}
              onSelectEvent={handleEventSelect}
              popup
              popupOffset={{ x: 30, y: 20 }}
            />
          )}
        </div>

        <div
          style={{
            width: "370px",
            padding: "10px",
            margin: "21px auto",
            marginTop: "21px",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            backgroundColor: "#fff",
            height: "fit-content",
            maxHeight: "31rem",
            overflowY: "scroll",
          }}
        >
          <Card
            style={{
              // width: 300,
              marginBottom: 20,
              backgroundColor: "#f7f7f7",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                style={{
                  color: "#333",
                  fontSize: "20px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Academy/School Details
              </Typography>

              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #e0e0e0",
                  margin: "10px 0",
                }}
              />

              <Typography
                color="textSecondary"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>Name:- </span> Crestwood Heights Academy
              </Typography>

              <Typography
                color="textSecondary"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span> Contact Details:- </span> 9698645432
              </Typography>
            </CardContent>
          </Card>

          <Typography
            variant="h6"
            gutterBottom
            style={{
              padding: "20px 0",
              textAlign: "center",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            Coach Details
          </Typography>
          {loadingCoaches ? (
            renderCoachSkeleton()
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2px",
                padding: "2px",
                justifyContent: "center",
              }}
            >
              {coaches.length > 0 ? (
                coaches.map((coach) => (
                  <Card
                    key={coach.id}
                    style={{
                      width: "100%",
                      marginBottom: 20,
                      backgroundColor: "#f7f7f7",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#333",
                          fontSize: "20px",
                          marginBottom: "10px",
                          textAlign: "center",
                        }}
                      >
                        {coach.name}
                      </Typography>

                      <hr
                        style={{
                          border: "none",
                          borderTop: "1px solid #e0e0e0",
                          margin: "10px 0",
                        }}
                      />

                      <Typography
                        color="textSecondary"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="pi pi-envelope"
                          style={{ marginRight: "5px" }}
                        ></span>{" "}
                        {coach.email}
                      </Typography>

                      <Typography
                        color="textSecondary"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="pi pi-phone"
                          style={{ marginRight: "5px" }}
                        ></span>{" "}
                        {coach.phone}
                      </Typography>

                      {/* <Typography
                        color="textSecondary"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="pi pi-graduation-cap"
                          style={{ marginRight: "5px" }}
                        ></span>{" "}
                        {coach.schoolID}
                      </Typography> */}
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography
                  style={{
                    color: "#888",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  No coaches found.
                </Typography>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Footer />
      </div> */}
      <Dialog
        header="Event Details"
        visible={eventDetailsVisible}
        style={{ height: "fit-content" }}
        onHide={() => setEventDetailsVisible(false)}
        className="new-cal-event-dialog"
      >
        {selectedEvent && (
          <div className="p-4 " style={{ marginTop: "50px" }}>
            <h2
              className="text-xl font-bold mb-2"
              style={{ fontSize: "24px", fontWeight: "bold" }}
            >
              {selectedEvent.title}
            </h2>
            <p className="mb-4">{selectedEvent.description}</p>
            <button
              onClick={() => attendance(selectedEvent)}
              className="btn btn-primary attendanceButton"
            >
              Attendence
            </button>
            <div className="mb-2">
              <br></br>
              <strong>
                <i
                  className="pi pi-calendar-clock"
                  style={{ color: "grey" }}
                ></i>{" "}
                Start Date:
              </strong>{" "}
              {new Date(selectedEvent.start).toLocaleDateString()}
            </div>
            <div style={{ marginBottom: "7px" }}>
              <strong>
                <i className="pi pi-clock" style={{ color: "grey" }}></i> Start
                Time:
              </strong>{" "}
              {new Date(selectedEvent.start).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <strong>
                <i className="pi pi-hourglass" style={{ color: "grey" }}></i>{" "}
                Duration:
              </strong>{" "}
              {formatDuration(
                new Date(selectedEvent.start),
                new Date(selectedEvent.end)
              )}
            </div>
          </div>
        )}
      </Dialog>

      <Dialog
        header="Attendance "
        visible={eventDetailsVisibleA}
        style={{ height: "fit-content", width: "850px" }}
        onHide={() => setEventDetailsVisibleA(false)}
        className="new-cal-event-dialog"
      >
        {selectedEvent && (
          <>
            <div className="row d-flex">
              <div style={{ width: "45%" }} className="col-6">
                <h2 className="heading">{team1Data}</h2>
                <DataTable
                  value={team1DataState}
                  selection={selectedStudents}
                  onSelectionChange={(e) =>
                    setSelectedStudents(e.value as Student[])
                  }
                  dataKey="id"
                  selectionMode="multiple"
                  className="data-table-popup"
                >
                  <Column field="name" header="Name" />
                  <Column
                    headerStyle={{ width: "3em" }}
                    body={(rowData: Student) => (
                      <Checkbox
                        checked={rowData.present}
                        onChange={() =>
                          updateStudentStatus(rowData.id, "present")
                        }
                      />
                    )}
                    header="Present"
                  />

                  <Column
                    headerStyle={{ width: "3em" }}
                    body={(rowData: Student) => (
                      <Checkbox
                        checked={rowData.absent}
                        onChange={() =>
                          updateStudentStatus(rowData.id, "absent")
                        }
                      />
                    )}
                    header="Absent"
                  />
                </DataTable>
              </div>

              <div style={{ width: "45%" }} className="col-6">
                <h2 className="heading">{team2Data}</h2>
                <DataTable
                  style={{ width: "100%" }}
                  value={team2DataState}
                  selection={selectedStudents}
                  onSelectionChange={(e) =>
                    setSelectedStudents(e.value as Student[])
                  }
                  dataKey="id"
                  selectionMode="multiple"
                  className="data-table-popup"
                >
                  <Column field="name" header="Name" />

                  <Column
                    headerStyle={{ width: "3em" }}
                    body={(rowData: Student) => (
                      <Checkbox
                        checked={rowData.present}
                        onChange={() =>
                          updateStudentStatus(rowData.id, "present")
                        }
                      />
                    )}
                    header="Present"
                  />
                  <Column
                    headerStyle={{ width: "3em" }}
                    body={(rowData: Student) => (
                      <Checkbox
                        checked={rowData.absent}
                        onChange={() =>
                          updateStudentStatus(rowData.id, "absent")
                        }
                      />
                    )}
                    header="Absent"
                  />
                </DataTable>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "3rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              className="buttonContainer"
            >
              <button onClick={saveAttendance} className="attendanceButton">
                Save
              </button>
            </div>
          </>
        )}
      </Dialog>

      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default Dashboard;
