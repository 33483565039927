import React, { useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { gql, useMutation } from "@apollo/client";
import { Badge, Calendar, Empty, Input, List, Typography, Table } from "antd";
import { TableColumnsType, TableProps } from "antd";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import dayjs, { Dayjs } from "dayjs";

interface Player {
  studentID: string;
  studentName: string;
  present: boolean;
  absent: boolean;
  late: boolean;
  totalTime: number;
  attendanceStatus: string;
}

const create_Event = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      message
      status
    }
  }
`;
const update_attandance = gql`
  mutation UpdateAttendanceRecordStatus($input: UpdateAttendanceStatusInput!) {
    updateAttendanceRecordStatus(input: $input) {
      attendanceRecords {
        attendanceStatus
        lateMinutes
        status
        studentID
        studentId
        studentName
        totalTime
      }
    }
  }
`;

const schoolID = localStorage.getItem("schoolID");
const schoolAcademic = localStorage.getItem("schoolAcademic");

const localizer = momentLocalizer(moment);

const eventTypeColors = {
  "Normal Event": "#90EE90", // light green
  "Inter House Event": "#ADD8E6", // light blue
  "Fixture Event": "#FFB6C1", // light red
};

function CalenderDashboard() {
  interface Student {
    age: number;
    email: string;
    gender: string | null;
    grade: string;
    id: number;
    name: string;
    intrest: string;
    schoolID: number;
  }

  interface AttendanceStatus {
    status: string;
    // present: boolean;
    // absent: boolean;
    // late: boolean;
  }

  interface AttendanceRecord {
    studentID: number;
    studentName: string;
    attendanceStatus: string;
  }

  interface Event {
    id: number;
    title: string;
    details: string;
    startTime: string;
    endTime: string;

    isAttendanceMarked: boolean;
    startDate: string; // You may want to consider using a number type if you're only using timestamps
    endDate: string; // Same as above
    attendanceRecords: AttendanceRecord[];
  }

  interface Attendance {
    studentName: string;
    studentID: number;
    attendanceStatus: string;
    lateMinutes: number;
    totalTime: number;
  }

  interface EventData {
    title: string;
    startDate: string;
    endDate: string;
    isAllDay: boolean;
    schoolID: number;
    description: string;
    isInterHouseEvent: boolean;
    house1Name: string;
    house2Name: string;
    typeOfSport: string;
    typeOfEvent: string;
    attendanceRecords: Attendance[];
  }

  const convertEventData = (reversed: Event[]) => {
    const events: {
      date: string;
      type: string;
      content: string;
      details: string;
      startTime: string;
      endTime: string;
      endDate: string;
      isAttendanceMarked: boolean;
      attendanceRecords?: AttendanceRecord[];
    }[] = [];

    console.log("reversed", reversed);

    reversed.forEach((event) => {
      const startDate = moment(parseInt(event.startDate)).format("YYYY-MM-DD");

      console.log("startDate", startDate);

      const date1 = new Date(Number(event.startDate));
      console.log("date1", date1);

      const hours = String(date1.getHours()).padStart(2, "0"); // Pad with leading zero if needed
      const minutes = String(date1.getMinutes()).padStart(2, "0");
      const seconds = String(date1.getSeconds()).padStart(2, "0");

      const startTime = `${hours}:${minutes}:${seconds}`;

      console.log("date", `${hours}:${minutes}:${seconds}`);

      const endDate = moment(parseInt(event.endDate)).format("YYYY-MM-DD");

      console.log("endDate", endDate);

      const date2 = new Date(Number(event.endDate));

      console.log("date2", date2);

      const hours1 = String(date2.getHours()).padStart(2, "0"); // Pad with leading zero if needed
      const minutes1 = String(date2.getMinutes()).padStart(2, "0");
      const seconds1 = String(date2.getSeconds()).padStart(2, "0");

      const endTime = `${hours1}:${minutes1}:${seconds1}`;

      events.push({
        date: startDate,
        type: "success",
        content: ` ${event.title}`,
        details: ` ${event.details}`,
        startTime: startTime,
        endTime: endTime,
        endDate: endDate,
        isAttendanceMarked: event.isAttendanceMarked,
        attendanceRecords: event?.attendanceRecords,
      });
    });

    return events;
  };

  function calculateDuration(startTime: string, endTime: string): string {
    // Parse the times into Date objects
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    // Calculate the duration in milliseconds
    let durationInMs = end.getTime() - start.getTime();

    // If the end time is less than the start time, add a full day in milliseconds
    if (durationInMs < 0) {
      durationInMs += 24 * 60 * 60 * 1000; // Add a full day
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor((durationInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((durationInMs / (1000 * 60)) % 60);
    const seconds = Math.floor((durationInMs / 1000) % 60);

    // Return the duration as a formatted string
    return `${hours}: ${minutes}: ${seconds}`;
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexTab, setActiveIndexTab] = useState(0);

  const [studentData, setStudentData] = useState<Student[]>([]);

  const [updateStatus, setUpdateStatus] = useState(false);

  const [createEvents, { loading: updatingProfile }] =
    useMutation(create_Event);
  const [updateAttandance, { loading: updatingAttandance }] =
    useMutation(update_attandance);

  const [players, setPlayers] = useState<Player[]>([]);

  const [eventStudentStatus, setEventStudentStatus] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("eventStudent");
    if (storedData) {
      setPlayers(JSON.parse(storedData));
    }
  }, [eventStudentStatus]);

  const [createEventStatus, setCreateEventStatus] = useState(false);

  useEffect(() => {
    const attendanceRecords: Attendance[] = studentData.map((student) => ({
      studentName: student.name,
      studentID: student.id,
      attendanceStatus: "PRESENT",
      lateMinutes: 0,
      totalTime: 0,
    }));

    setEventData((prevEventData) => ({
      ...prevEventData,
      attendanceRecords,
    }));
  }, [studentData]);

  const [eventData, setEventData] = useState<EventData>({
    title: "",
    startDate: "",
    endDate: "",
    isAllDay: false,
    schoolID: schoolID ? parseInt(schoolID, 10) : 0,
    description: "",
    isInterHouseEvent: false,
    house1Name: "",
    house2Name: "",
    typeOfSport: "",
    typeOfEvent: "",
    attendanceRecords: [],
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onchangeStartDate = (e: any) => {
    const dateString = e.target.value;
    setStartDate(dateString);
    const isoDate = new Date(dateString).toISOString();

    setEventData((prev) => ({
      ...prev,
      startDate: isoDate,
    }));
  };
  const onchangeEndDate = (e: any) => {
    const dateString = e.target.value;
    setEndDate(dateString);
    const isoDate = new Date(dateString).toISOString();

    setEventData((prev) => ({
      ...prev,
      endDate: isoDate,
    }));
  };

  const onchangeData = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const [events, setEvents] = useState<any[]>([]);

  const reversed = [...events].reverse();

  const toast = useRef<any>(null);

  const eventStyleGetter = (event: any) => {
    const backgroundColor =
      eventTypeColors[event.typeOfEvent as keyof typeof eventTypeColors] ||
      "#3174ad";
    return { style: { backgroundColor } };
  };

  const handleEventSelect = (_event: any) => {};

  const showToast = (severity: string, summary: string, detail: string) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail, life: 3000 });
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  const usertype = localStorage.getItem("usertype");

  useEffect(() => {
    fetchEvents();
  }, [updateStatus]);

  const fetchEvents = async () => {
    const query = `
    query GetAllEvents {
      getAllEvents {
        id
        title
        startDate
        endDate
        
        details
        isAttendanceMarked
        attendanceRecords {
          studentID
          studentName
          lateMinutes
          totalTime
          attendanceStatus
          
        }
      }
    }
  `;

    const token = localStorage.getItem("token");

    if (!token) {
      alert("Authentication token is missing");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query }),
      });

      const result = await response.json();

      const eventsData = result.data.getAllEvents.map((event: any) => ({
        id: event.id,
        title: event.title,
        attendanceRecords: event?.attendanceRecords,
        isAttendanceMarked: event?.isAttendanceMarked,
        startDate: event?.startDate,
        endDate: event?.endDate,
        details: event?.details
          ? JSON.parse(event?.details)?.description
          : null,
      }));

      console.log("eventsData", eventsData);

      setEvents(eventsData);
    } catch (error) {
      console.error("Error fetching events:", error);
      // showToast("error", "Error", "An error occurred while fetching data");
    }
  };
  const getStudents = async () => {
    const schoolId = localStorage.getItem("schoolID");
    const query = `
      query GetStudentsBySchoolId($schoolId: Int!) {
        getStudentsBySchoolId(schoolId: $schoolId) {
          age
          email
          gender
          grade
          id
          name
          intrest
          schoolID
        }
      }
    `;

    const token = localStorage.getItem("token");

    if (!token) {
      alert("Authentication token is missing");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query,
          variables: { schoolId: Number(schoolId) },
        }),
      });

      const result = await response.json();

      if (result.errors) {
        console.error("GraphQL Errors:", result.errors);
        // showToast("error", "Error", "An error occurred.");
        return;
      }

      // Process the result data
      const students = result.data.getStudentsBySchoolId;
      setStudentData(students);
      return students; // Return the students data if needed
    } catch (error) {
      console.error("Error fetching students:", error);
      // showToast("error", "Error", "An error occurred while fetching data");
    }
  };

  const attendance1 = async (details: any) => {
    localStorage.setItem("eventId", details?.id);

    if (details?.attendanceRecords) {
      localStorage.setItem(
        "eventStudent",
        JSON.stringify(details.attendanceRecords)
      );
    }

    setEventStudentStatus((prev) => !prev);

    // setActiveIndexTab(index);
  };

  const [selecedData, setSelectedData] = useState<any>(null);

  console.log("selecedData", selecedData);

  const attendance = async (details: any, index: any) => {
    localStorage.setItem("eventId", details?.id);

    setSelectedData(details);

    if (details?.attendanceRecords) {
      localStorage.setItem(
        "eventStudent",
        JSON.stringify(details.attendanceRecords)
      );
    }

    setEventStudentStatus((prev) => !prev);

    setActiveIndexTab(index);
  };

  const updateAttendanceStatus = (studentID: string, newStatus: string) => {
    const updatedPlayers = players.map((player) => {
      if (player.studentID === studentID) {
        return { ...player, attendanceStatus: newStatus };
      }
      return player;
    });

    setPlayers(updatedPlayers);
    localStorage.setItem("eventStudent", JSON.stringify(updatedPlayers)); // Update local storage
  };

  const createEvent = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await createEvents({
        variables: {
          input: {
            ...eventData,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      setCreateEventStatus(false);

      setEventData({
        title: "",
        startDate: "",
        endDate: "",
        isAllDay: false,
        schoolID: schoolID ? parseInt(schoolID, 10) : 0,
        description: "",
        isInterHouseEvent: false,
        house1Name: "",
        house2Name: "",
        typeOfSport: "",
        typeOfEvent: "",
        attendanceRecords: [],
      });

      setUpdateStatus((prev) => !prev);

      setStartDate("");
      setEndDate("");

      showToast(
        "success",
        "Event Created",
        response?.data?.createEvent?.message
      );
    } catch (err) {
      console.error("Error:", err);
    }
  };
  const updateAttendance = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const storedData = localStorage.getItem("eventStudent");
    const eventId = localStorage.getItem("eventId");
    const eventStudentData = storedData ? JSON.parse(storedData) : [];

    const data = {
      eventId: Number(eventId),
      attendanceRecords: eventStudentData,
    };

    try {
      const response = await updateAttandance({
        variables: {
          input: {
            ...data,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      setUpdateStatus((prev) => !prev);

      setStartDate("");
      setEndDate("");
    } catch (err) {
      console.error("Error:", err);
      showToast("success", " Updated", "Attendance updated ");
    }
  };

  const updateData = () => {
    console.log("update");
  };

  const event1 = convertEventData(reversed);

  const getListData = (value: Moment) => {
    const dateString = value.format("YYYY-MM-DD");
    return event1.filter((event) => event.date === dateString);
  };

  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const dateCellRender = (value: Dayjs) => {
    const momentValue = moment(value.toDate()); // Convert Dayjs to Moment
    const listData = getListData(momentValue);
    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li
            style={{
              position: "absolute",
              width: "100%",
              background: "blue",
              height: "4px",
              top: "-1px",
              left: "0px",
            }}
            key={index}
          ></li>
        ))}
      </ul>
    );
  };

  const onSelectDate = (date: Dayjs) => {
    const momentDate = moment(date.toDate());
    setSelectedDate(momentDate);
  };

  return (
    <div>
      <div className="sidebar-container1">
        <div className="sidebar-menu2"></div>
        <div className="nav-menu1">
          <div className="first">
            <div className="firstcol1">
              <div>
                <p className="calenderhead">Calendar</p>
              </div>
              <div>
                <p
                  onClick={() => {
                    usertype == "coach"
                      ? setCreateEventStatus(true)
                      : setCreateEventStatus(false);
                  }}
                  className="nwevent cursorPointer"
                >
                  {" "}
                  <i className="pi pi-plus" style={{ fontSize: "10px" }}></i>New
                  Event
                </p>
              </div>
            </div>
            <div className="card flex justify-content-center">
              <div style={{ margin: "14px 0px" }}>
                <Calendar cellRender={dateCellRender} onSelect={onSelectDate} />

                {/* <BigCalendar
                  localizer={localizer}
                  // events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 300, width: "100%" }}
                  views={["month"]}
                  toolbar={true}
                  selectable={false}
                  onSelectEvent={handleEventSelect}
                  eventPropGetter={eventStyleGetter}
                /> */}
              </div>
            </div>

            <div>
              <div className="tab-headers">
                <button
                  onClick={() => setActiveIndex(0)}
                  className={activeIndex == 0 ? "tab-active" : "tab-button"}
                >
                  All
                </button>
                <button
                  onClick={() => setActiveIndex(1)}
                  className={activeIndex == 1 ? "tab-active" : "tab-button"}
                >
                  Practice
                </button>
                <button
                  onClick={() => setActiveIndex(2)}
                  className={activeIndex == 2 ? "tab-active" : "tab-button"}
                >
                  Try Out
                </button>
                <button
                  onClick={() => setActiveIndex(3)}
                  className={activeIndex == 3 ? "tab-active" : "tab-button"}
                >
                  League
                </button>
              </div>
            </div>

            {activeIndex == 0 ? (
              <>
                <div style={{ marginTop: 20 }}>
                  <List
                    dataSource={getListData(selectedDate)}
                    renderItem={(item, index) => (
                      <>
                        <div
                          onClick={() => attendance(item, index)}
                          className={
                            activeIndexTab == index
                              ? "tab-cards-active cursorPointer"
                              : "tab-cards cursorPointer"
                          }
                        >
                          {/* setActiveIndexTab(index) */}
                          <div className="tabcardbox1">
                            <div>
                              <p className="main"> {item?.content}</p>
                              {/* <p className="sub">{item?.description}</p> */}
                              {/* <p className="para">{item?.typeOfEvent}</p> */}
                            </div>
                            <div className="tabboxtoggle">
                              {item?.isAttendanceMarked ? (
                                <span id="markcolor" className="markcolor">
                                  <p
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <i
                                      className="pi pi-circle-fill "
                                      style={{ fontSize: "6px" }}
                                    ></i>{" "}
                                    Attendence Marked
                                  </p>
                                </span>
                              ) : (
                                <span
                                  id="pendingcolor"
                                  className="pendingcolor"
                                >
                                  <p
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <i
                                      className="pi pi-circle-fill "
                                      style={{ fontSize: "6px" }}
                                    ></i>{" "}
                                    Attendence Pending
                                  </p>
                                </span>
                              )}

                              <p>
                                <i className="pi pi-ellipsis-v"></i>
                              </p>
                            </div>
                          </div>

                          <hr className="hrborder" />

                          <div className="tabcardbox2">
                            <div>
                              <p className="sub">{item?.details}</p>
                              <p className="para"> {item?.date}</p>
                            </div>
                            <div className="tabbox2toggle">
                              <p className="sidepara">
                                {" "}
                                {calculateDuration(
                                  item?.startTime,
                                  item?.endTime
                                )}{" "}
                                hrs{" "}
                              </p>
                              {/* <p className="para"> Duration:2 hours</p> */}
                            </div>
                          </div>

                          <hr className="hrborder" />
                          <div className="tabcardbox3">
                            <p className="para">OVERTIME</p>
                            <p className="sidepara">
                              <span>
                                <i className="pi pi-plus"></i>
                              </span>{" "}
                              Add Overtime
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </div>

                <div></div>
              </>
            ) : (
              ""
            )}
            {activeIndex == 1 ? <div>1</div> : ""}
            {activeIndex == 2 ? <div>2</div> : ""}
            {activeIndex == 3 ? <div>3</div> : ""}
          </div>

          <div className="second">
            <div style={{ width: "100%" }}>
              <div className="uppercolumn">
                <div className="markattendence">
                  <p>
                    <i className="pi pi-angle-left"></i> Mark Attendence{" "}
                  </p>
                </div>
                <div>
                  <p className="download cursorPointer">
                    <i className="pi pi-download"></i> download excel{" "}
                  </p>
                </div>
              </div>
              {selecedData && (
                <div className="uppercolumn2">
                  <div className="uppercol1">
                    <p className="upperheadmain">
                      {selecedData?.content || "Sports Name"}
                    </p>
                    <p className="upperheadsub">
                      {selecedData?.details || "No Sport"}
                    </p>
                  </div>
                  <div className="uppercol2">
                    <p className="upperheadmain">
                      Time: {selecedData?.startTime || "--"} to{" "}
                      {selecedData?.endTime || "--"}
                    </p>
                    <p className="upperheadsub">
                      {selecedData?.date || "yyyy-mm-dd"}
                    </p>
                  </div>
                  <div className="uppercol3">
                    <div>
                      <p className="pending">
                        {selecedData?.isAttendanceMarked ? (
                          <span>Marked</span>
                        ) : (
                          <span>Pending</span>
                        )}{" "}
                      </p>
                      <p className="upperheadsub">Attendence Status</p>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={
                        usertype == "coach" ? updateAttendance : updateData
                      }
                    >
                      <p className="download1"> Save Attendence</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="main-table">
                {/* <Table<Player>
                  rowSelection={{ type: "checkbox", ...rowSelection }} // Specify selectionType
                  columns={columns}
                  dataSource={players}
                  rowKey="studentID" // Unique key for each row
                /> */}

                {selecedData ? (
                  <table style={{ overflowX: "scroll" }} className="table-main">
                    <thead>
                      <tr
                        style={{
                          textAlign: "left",
                          borderBottom: "1px solid var(--paracolor)",
                        }}
                        className="d"
                      >
                        <th className="tableheadingfont">Student Id</th>
                        <th className="tableheadingfont">Student Name</th>
                        <th className="tableheadingfont" id="font2">
                          <div>Status</div>
                          <div>
                            <p className="select">
                              <i
                                className="pi pi-check-circle"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              ></i>
                              select all
                            </p>
                          </div>
                        </th>
                        <th
                          style={{ width: "10rem" }}
                          className="tableheadingfont"
                        >
                          Total Attd. Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {players.length > 0 ? (
                        players.map((item, _index) => (
                          <tr
                            key={item.studentID}
                            style={{
                              borderBottom: "1px solid var(--paracolor)",
                            }}
                          >
                            <td className="tablefont">{item.studentID}</td>
                            <td className="tablefont">{item.studentName}</td>
                            <td className="table-status">
                              <button
                                className={
                                  item.attendanceStatus === "PRESENT"
                                    ? "activeC"
                                    : "decableC"
                                }
                                onClick={() =>
                                  updateAttendanceStatus(
                                    item.studentID,
                                    "PRESENT"
                                  )
                                }
                                disabled={usertype !== "coach"}
                              >
                                <i className="pi pi-check"></i> Present
                              </button>
                              <button
                                className={
                                  item.attendanceStatus === "ABSENT"
                                    ? "absentactive"
                                    : "decableC"
                                }
                                onClick={() =>
                                  updateAttendanceStatus(
                                    item.studentID,
                                    "ABSENT"
                                  )
                                }
                                disabled={usertype !== "coach"}
                              >
                                <i className="pi pi-times"></i> Absent
                              </button>
                              <button
                                className={
                                  item.attendanceStatus === "LATE"
                                    ? "Late"
                                    : "decableC"
                                }
                                onClick={() =>
                                  updateAttendanceStatus(item.studentID, "LATE")
                                }
                                disabled={usertype !== "coach"}
                              >
                                <i className="pi pi-clock"></i> Late
                              </button>
                            </td>
                            <td
                              style={{ width: "10rem" }}
                              className="tablefont"
                            >
                              {item.totalTime} Hrs
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={4}
                            style={{ textAlign: "center", height: "50vh" }}
                          >
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <>
                    <div className="align-table">
                      <h2>No Data</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} position="top-right" />

      <Dialog
        header="Create Event"
        visible={createEventStatus}
        style={{ width: "40vw", position: "relative", zIndex: "11" }}
        onHide={() => {
          if (!createEventStatus) return;
          setCreateEventStatus(false);
        }}
      >
        <form>
          <div>
            <div style={{ width: "100%" }}>
              <label>Title</label>
              <Input
                size="large"
                onChange={onchangeData}
                name="title"
                value={eventData?.title}
                placeholder="Title"
              />
            </div>
            <div style={{ width: "100%", marginTop: "8px" }}>
              <label>Description</label>
              <Input
                size="large"
                onChange={onchangeData}
                name="description"
                value={eventData?.description}
                placeholder="Description"
              />
            </div>
            {/* <div
              style={{ width: "100%", marginTop: "8px", position: "relative" }}
            >
              <label>School</label>
              <Select
                style={{ width: "100%", position: "relative", zIndex: "12" }}
                size="large"
                placeholder="School"
                options={[
                  { value: "1", label: "Jack" },
                  { value: "2", label: "Lucy" },
                  { value: "3", label: "Tom" },
                ]}
              />
            </div> */}

            {schoolAcademic == "ACADEMIC" ? (
              <div
                style={{
                  width: "100%",
                  marginTop: "8px",
                  position: "relative",
                }}
              >
                <label>Select Age Group</label>
                <select
                  onChange={onchangeData}
                  value={eventData?.typeOfSport}
                  name="typeOfSport"
                  className="inputboxs"
                  id="cars"
                >
                  <option value="">Plsease Select</option>
                  <option value="9">Under 9</option>
                  <option value="14">Under 14</option>
                  <option value="16">Under 16</option>
                  <option value="19">Under 19</option>
                </select>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  marginTop: "8px",
                  position: "relative",
                }}
              >
                <label>Type Of Sport</label>
                <select
                  onChange={onchangeData}
                  value={eventData?.typeOfSport}
                  name="typeOfSport"
                  className="inputboxs"
                  id="cars"
                >
                  <option value="">Plsease Select</option>
                  <option value="Football">Football</option>
                  <option value="Cricket">Cricket</option>
                  <option value="Hockey">Hockey</option>
                  <option value="Tennis">Tennis</option>
                </select>
              </div>
            )}

            <div
              style={{ width: "100%", marginTop: "8px", position: "relative" }}
            >
              <label>startDate</label>
              <div>
                <input
                  type="datetime-local"
                  name="startDate"
                  onChange={onchangeStartDate}
                  value={startDate}
                  className="inputboxs"
                />
              </div>
              {/* <div>
                <input
                  type="datetime-local"
                  name="startDate"
                  onChange={onchangeData}
                  value={eventData?.startDate}
                  className="inputboxs"
                />
              </div> */}
            </div>
            <div
              style={{ width: "100%", marginTop: "8px", position: "relative" }}
            >
              <label>endDate</label>
              <div>
                <input
                  name="endDate"
                  onChange={onchangeEndDate}
                  value={endDate}
                  type="datetime-local"
                  className="inputboxs"
                />
              </div>
              {/* <div>
                <input
                  name="endDate"
                  onChange={onchangeData}
                  value={eventData?.endDate}
                  type="datetime-local"
                  className="inputboxs"
                />
              </div> */}
            </div>

            <div
              onClick={createEvent}
              className=""
              style={{ marginTop: "12px", textAlign: "right" }}
            >
              <button className="button">Submit</button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
}

export default CalenderDashboard;
