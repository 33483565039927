import React, { useEffect, useState } from "react";
import Navbar2 from "../Navbar/Navbar2";

import "./Calender1.css";
import SideBar from "../SideBar/SideBar";
import CalenderDashboard from "../CalenderDashboard/CalenderDashboard";
import { useLocation } from "react-router-dom";
import Tournaments1 from "../Tournaments/Tournaments1";
import DashBoard from "../Dashboard/DashBoard";

interface Coach {
  id: string;
  name: string;
  email: string;
  phone: string;
  schoolID: string;
}
function Calendar1() {
  const [activeLink, setActiveLink] = useState("");

  const location = useLocation();

  const [coaches, setCoaches] = useState<Coach[]>([]);

  const [moderatorAccess, setModeratorAccess] = useState<boolean>(false);

  const fetchGraphQLData = async (query: string): Promise<any> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No JWT token found");
    }

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `jwt ${token}`,
      },
      body: JSON.stringify({ query }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  useEffect(() => {
    // Get the last segment from the pathname
    const pathSegments = location.pathname.split("/");
    const currentLink = pathSegments[pathSegments.length - 1];
    setActiveLink(currentLink);
  }, [location]);

  useEffect(() => {
    const userType = localStorage.getItem("usertype");

    const fetchData = async () => {
      try {
        let query;
        if (userType === "coach") {
          query = `
            query Coach {
              coach {
                id
                name
                email
                phone
                schoolID
                schoolName
                typeOfSport
                
              }
            }
          `;
        } else if (userType === "student") {
          query = `
            query Student {
              student {
                id
                email
                name
                schoolID
                grade
                age
                moderatorAccess
                intrestApprovalStatus
               intrest
               
              }
            }
          `;
        } else {
          console.error("Invalid user type");
          throw new Error("Invalid user type");
        }

        const data = await fetchGraphQLData(query);
        if (userType === "student") {
          const {
            id,
            email,
            name,
            schoolID,
            grade,
            age,
            moderatorAccess,
            intrestApprovalStatus,
            intrest,
            // academicOrSchool,
          } = data.data.student;
          localStorage.setItem("id", id.toString());
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          // localStorage.setItem("academicOrSchool", academicOrSchool);
          localStorage.setItem("schoolID", schoolID.toString());
          localStorage.setItem("grade", grade);
          localStorage.setItem("age", age.toString());
          localStorage.setItem("moderatorAccess", moderatorAccess.toString());
          localStorage.setItem(
            "intrestApprovalStatus",
            intrestApprovalStatus.toString()
          );
          localStorage.setItem("intrest", intrest.toString());
          setModeratorAccess(moderatorAccess);
        } else if (userType === "coach") {
          const {
            id,
            email,
            name,
            phone,
            schoolID,
            typeOfSport,
            // academicOrSchool,
          } = data.data.coach;

          localStorage.setItem("id", id.toString());
          localStorage.setItem("email", email);
          // localStorage.setItem("academicOrSchool", academicOrSchool);
          localStorage.setItem("name", name);
          localStorage.setItem("typeOfSport", JSON.stringify(typeOfSport));
          localStorage.setItem("phone", phone);
          localStorage.setItem("schoolID", schoolID.toString());
          localStorage.setItem("school", data.data.coach.schoolName);
        }

        // Fetch all coaches regardless of user type
        const allCoachesQuery = `
          query GetAllCoaches {
            getAllCoaches {
              id
              name
              email
              phone
              schoolID
            }
          }
        `;
        const allCoachesData = await fetchGraphQLData(allCoachesQuery);
        setCoaches(allCoachesData.data.getAllCoaches || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("activeLink", activeLink);

  return (
    <>
      <div>
        <Navbar2 />
        <div className="navbar12"></div>
        <SideBar />

        {activeLink === "calender" ? (
          <CalenderDashboard />
        ) : activeLink == "tournament" ? (
          <Tournaments1 />
        ) : activeLink == "dashboard" ? (
          <DashBoard />
        ) : (
          <>no data</>
        )}
      </div>
    </>
  );
}

export default Calendar1;
