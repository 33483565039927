import React, { useState, useEffect, useRef } from "react";
import "../styles/Profile.css";
import Navbar from "./Navbar";
import { Select } from "antd";
import { ApolloClient, createHttpLink, gql, useMutation } from "@apollo/client";
import { Toast } from "primereact/toast";

// interface ProfileProps {
//   name: string;
//   mobile: string;
//   email: string;
//   schoolId: string;
// }

interface InterestOption {
  value: string;
  label: string;
}

const UPDATE_PROFILE = gql`
  mutation UpdateCoach($coachId: Int!, $input: UpdateCoachInput!) {
    updateCoach(id: $coachId, input: $input) {
      status
      message
      coach {
        id
        name
        email
        phone
        typeOfSport
      }
    }
  }
`;
const UPDATE_PROFILE_STUDENT = gql`
  mutation UpdateStudentInterest($studentId: Int!, $interest: String!) {
    updateStudentInterest(studentId: $studentId, interest: $interest) {
      message
      status
      intrestApprovalStatus
    }
  }
`;

const Profile: React.FC = () => {
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [mobile, setMobile] = useState(localStorage.getItem("mobile") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");

  const [studentIntrest, setStudentIntrest] = useState(
    String(localStorage.getItem("intrest"))
  );

  const coachId = localStorage.getItem("id");
  const schoolAcademic = localStorage.getItem("schoolAcademic");

  const toast = useRef<any>(null);

  const showToast = (severity: string, summary: string, detail: string) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail, life: 3000 });
    }
  };

  const [updateProfile, { loading: updatingProfile }] =
    useMutation(UPDATE_PROFILE);

  const [updateProfileStudent, { loading: updatingProfileStudent }] =
    useMutation(UPDATE_PROFILE_STUDENT);

  // Retrieve the data from localStorage
  const dataCoachString = localStorage.getItem("typeOfSport");

  console.log("dataCoachString", dataCoachString);

  const [sport, setSport] = useState<string[]>([]);
  const [eventData, seteventData] = useState("");

  useEffect(() => {
    const dataCoachString = localStorage.getItem("typeOfSport");
    if (dataCoachString) {
      const parsedSports: string[] = JSON.parse(dataCoachString);
      setSport(parsedSports);
    }
  }, []);

  const [schoolId, setSchoolId] = useState(
    localStorage.getItem("schoolId") || ""
  );
  const [school, setSchool] = useState("");
  const [intrestApprovalStatus, setIntrestApprovalStatus] = useState(
    localStorage.getItem("intrestApprovalStatus")
  );
  const usertype = localStorage.getItem("usertype");
  useEffect(() => {
    const email = localStorage.getItem("email");

    const schoolId = localStorage.getItem("schoolId");
    const school = email?.split("@")[1];

    if (school === "school1.com") {
      setSchool("School 1");
    } else if (school === "school2.com") {
      setSchool("School 2");
    } else if (school === "school3.com") {
      setSchool("School 3");
    } else if (school === "school4.com") {
      setSchool("School 4");
    }
  });

  const handleChange = (selectedSports: string[]) => {
    setSport(selectedSports);
  };

  const interestOptions: InterestOption[] = [
    {
      value: "Football",
      label: "Football",
    },
    {
      value: "Cricket",
      label: "Cricket",
    },
    {
      value: "Hockey",
      label: "Hockey",
    },
    {
      value: "Tennis",
      label: "Tennis",
    },
  ];
  const interestOptions1: InterestOption[] = [
    {
      value: "9",
      label: "Under 9",
    },
    {
      value: "14",
      label: "Under 14",
    },
    {
      value: "16",
      label: "Under 16",
    },
    {
      value: "19",
      label: "Under 19",
    },
  ];

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission
    const token = localStorage.getItem("token");
    try {
      const response = await updateProfile({
        variables: {
          coachId: Number(coachId),
          input: {
            typeOfSport: sport,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      localStorage.setItem("typeOfSport", JSON.stringify(sport));

      showToast(
        "success",
        "Profile Updated",
        response?.data?.updateCoach?.message
      );

      console.log("Response:", response?.data?.updateCoach?.message);
    } catch (err) {
      console.error("Error:", err);
    }
  };
  const handleUpdateProfileStudent = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission
    const token = localStorage.getItem("token");
    const studentId = localStorage.getItem("id");
    try {
      const response = await updateProfileStudent({
        variables: {
          interest: studentIntrest,
          studentId: Number(studentId),
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      showToast(
        "success",
        "Profile Updated",
        response?.data?.updateCoach?.message
      );

      console.log("Response:", response);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <>
      <Navbar buttontext="" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <section className="profile-card">
          <div className="image">
            <img
              src="https://img-new.cgtrader.com/items/4506145/4d6ab481d2/large/football-player-avatar-3d-icon-3d-model-4d6ab481d2.jpg"
              alt="user image"
            />
          </div>
          <div className="text-data">
            <h2>{name}</h2>
            <p>{mobile}</p>
            <p>{email}</p>
            <p>{school}</p>
            {usertype == "student" ? (
              <p>ApprovalStatus :{intrestApprovalStatus}</p>
            ) : (
              ""
            )}
            {/* <p>{schoolId}</p> */}
          </div>

          {usertype == "student" ? (
            <>
              <div style={{ textAlign: "left", width: "100%" }}>
                <label htmlFor="intrest">Intrest</label>
                <select
                  value={studentIntrest}
                  onChange={(e) => {
                    setStudentIntrest(e.target.value);
                  }}
                  className="inputboxs"
                  name="cars"
                  id="cars"
                >
                  <option value="">Please Select</option>
                  <option value="Football">Football</option>
                  <option value="Cricket">Cricket</option>
                  <option value="Hockey">Hockey</option>
                  <option value="Tennis">Tennis</option>
                </select>
              </div>

              <div
                style={{ marginTop: "12px", textAlign: "end", width: "100%" }}
              >
                <button onClick={handleUpdateProfileStudent} className="button">
                  Update Profile
                </button>
              </div>
            </>
          ) : (
            <>
              {schoolAcademic == "ACADEMIC" ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    position: "relative",
                  }}
                >
                  <label>Select Age Group</label>

                  <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={sport}
                    onChange={handleChange}
                    options={interestOptions1}
                  />
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <label htmlFor="intrest">Coach Intrest</label>
                  <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={sport}
                    onChange={handleChange}
                    options={interestOptions}
                  />
                </div>
              )}

              <div
                style={{ marginTop: "12px", textAlign: "end", width: "100%" }}
              >
                <button onClick={handleUpdateProfile} className="button">
                  Update Profile
                </button>
              </div>
            </>
          )}
        </section>

        <Toast ref={toast} position="top-right" />
      </div>
    </>
  );
};

export default Profile;
